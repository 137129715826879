import { notificationTypes } from '@/helpers/enums';
import store from '@/store';

export const notificationOptions = {
	icon: '/logo.png',
	dir: 'rtl',
	lang: 'ar-SA',
};

export const notificationPageRedirect = [
	{ type: notificationTypes.message, redirect: '/chat', routeName: 'chat' },
	{ type: notificationTypes.attendance, redirect: '/attendance', routeName: 'attendance' },
	{ type: notificationTypes.newMark, redirect: '/grades', routeName: 'grades' },
	{ type: notificationTypes.newMarks, redirect: '/grades', routeName: 'grades' },
	{ type: notificationTypes.schedule, redirect: '/schedule', routeName: 'schedule' },
	{ type: notificationTypes.newTest, redirect: '/exams', routeName: 'exams' },
	{ type: notificationTypes.testsSchedule, redirect: '/exams', routeName: 'exams' },
	{ type: notificationTypes.transport, redirect: '/', routeName: 'home' },
	{ type: notificationTypes.notes, redirect: '/behavior', routeName: 'behavior' },
];

export const firebase = {
	configs: {
		apiKey: 'AIzaSyByXz59B8044U_l3IFyAnkOQrl2jWpQzPY',
		authDomain: 'rowad-almostakbal-app.firebaseapp.com',
		projectId: 'rowad-almostakbal-app',
		storageBucket: 'rowad-almostakbal-app.appspot.com',
		messagingSenderId: '715392287599',
		appId: '1:715392287599:web:2dbd370590d05957411e9a'	  
	},
	publicKey: 'BO8ruJTGX7IySyTEAT4ULuBOLJ1Wv_4H04V8CxNhfVP3daS6uO7m4Fh59EoQzcKdRYLESxAoQFPce3j9vnjbcaQ',
};

export const onGetNotification = () => {
	store.dispatch('notification/fetchAll');
};
